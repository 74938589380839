<template>
  <section id="content" style="margin-bottom: 80px ">
    <v-card
      class="mx-auto py-1 mb-2 border-radius 0px;"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px; border-radius 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="mx-auto py-0 py-md-6">
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            Retail Nursery
          </v-card-title>
          <v-card-text class="text-center title font-weight-light mx-auto mb-5">
            We have a large collection of Indigenous species of plants and trees
            laid out to encourage leisurely shopping; each species has
            individual detailed information labels to ensure your choice is not
            compromised. We are open 7 days a week; you can browse or if you
            need assistance a competent staff member dedicated to planting
            indigenous is on hand to enhance your gardening choice and to help
            you.
            <br /><br />
            We are always striving to introduce 'New' species to our range, as
            there is such a choice. Making our clients aware of this is a great
            challenge, of how to use our indigenous and endemic plants.
            <br /><br />
            Remember you can order plants in advance; pick them up at a
            prearranged time convenient to you, just give us a call. Our plants
            are priced according to bag size, Shrubs, ground covers, Aloes
            starting at 3 litre to 20 litre followed by Trees in 10 litre to 100
            litre. Aloes/ferns have a different pricing structure. Contact us
            for up to date prices and stock lists.
          </v-card-text>
        </v-container>

        <center>
          <v-btn to="/specials" color="light-green">
            Arbour Month Special Giveaway
          </v-btn>
        </center>
        <br />
        <center>
          <v-btn
            color="thirdley"
            class="ml-3"
            tile
            outlined
            href="/static/StockList.xlsx"
          >
            Download Stock List (May 2021) &nbsp;&nbsp;
            <span style="font-size: 16px;">
              <font-awesome-icon
                class="ml-3"
                right
                :icon="['fas', 'file-download']"
              />
            </span>
          </v-btn>
        </center>

        <!-- <v-overlay hidden absolute :v-if="(ShowMenuClicked = true)">
          <v-row>
            <v-col cols="10">
              <iframe
                v-if="ShowMenuClicked"
                src="/static/StockList.xlsx"
                :height="[$vuetify.breakpoint.smAndUp ? '600' : '400']"
                :width="[$vuetify.breakpoint.smAndUp ? '400' : '250']"
              />
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="ShowMenuClicked = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-overlay> -->

        <v-card-text
          class="text-center display-1 font-weight-light mx-auto mb-5"
        >
          Shrub and Ground Cover Sizes
        </v-card-text>
        <div style="padding: 0 20px 20px 0; ">
          <v-row justify="space-between">
            <div style="padding-top: 130px;">
              <img src="https://img.icons8.com/color/15/000000/willow.png" />
              <h1 style="text-align:center;" class="pb-0">
                0.5L
              </h1>
            </div>

            <div style="padding-top: 119px;">
              <img src="https://img.icons8.com/color/30/000000/willow.png" />
              <h1 style="text-align:center;" class="pb-0">
                3L
              </h1>
            </div>

            <div style="padding-top: 87px;">
              <img src="https://img.icons8.com/color/60/000000/willow.png" />
              <h1 style="text-align:center;" class="pb-0">
                5L
              </h1>
            </div>

            <div style="padding-top: 58px;">
              <img src="https://img.icons8.com/color/90/000000/willow.png" />
              <h1 style="text-align:center;" class="pb-0">
                10L
              </h1>
            </div>

            <div>
              <img src="https://img.icons8.com/color/150/000000/willow.png" />
              <h1 style="text-align:center;" class="pb-0">
                20L
              </h1>
            </div>
          </v-row>
        </div>
        <br />
        <!-- <v-img   :src="require('@/assets/Main/sizes/10L S.png')"></v-img> -->
        <v-card-text
          class="text-center display-1 font-weight-light mx-auto mb-5"
        >
          Tree Sizes
        </v-card-text>

        <v-row justify="space-between">
          <div style="padding-top: 130px;">
            <img src="https://img.icons8.com/color/15/000000/acacia.png" />
            <h1 style="text-align:center;" class="pb-0">
              10L
            </h1>
          </div>

          <div style="padding-top: 119px;">
            <img src="https://img.icons8.com/color/30/000000/acacia.png" />
            <h1 style="text-align:center;" class="pb-0">
              20L
            </h1>
          </div>

          <div style="padding-top: 87px;">
            <img src="https://img.icons8.com/color/60/000000/acacia.png" />
            <h1 style="text-align:center;" class="pb-0">
              40L
            </h1>
          </div>

          <div style="padding-top: 58px;">
            <img src="https://img.icons8.com/color/90/000000/acacia.png" />
            <h1 style="text-align:center;" class="pb-0">
              60L
            </h1>
          </div>

          <div>
            <img src="https://img.icons8.com/color/150/000000/acacia.png" />
            <h1 style="text-align:center;" class="pb-0">
              100L
            </h1>
          </div>
        </v-row>
        <v-card class="mx-auto" height="70vh" tile>
          <v-carousel height="70vh" cycle hide-delimiters show-arrows-on-hover>
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            />
          </v-carousel>
        </v-card>

        <section id="details" class="mx-auto pt-5 pb-0 mb-2">
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div color="thirdary" class="mb-2 title" v-text="info1" />
                    <div color="thirdary" v-text="info2" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet class="mx-auto py-0 mb-2" color="grey lighten-2">
        <p style="text-align: center;">
          <strong>Open</strong>
          <br />
          <em>Monday:</em> closed
          <br />
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br />
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br />
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "SampleContents",

  data: () => ({
    items: [
      {
        src: require("@/assets/Main/retail/nursery (2).webp")
      },
      {
        src: require("@/assets/Main/retail/nursery (3).webp")
      },
      {
        src: require("@/assets/Main/retail/nursery (4).webp")
      },
      // {
      //   src: require('@/assets/Main/retail/nursery (1).webp')
      // },
      {
        src: require("@/assets/Main/retail/nursery (7).webp")
      },
      {
        src: require("@/assets/Main/retail/nursery (10).webp")
      }
    ],
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],
    socials: [
      [
        "https://www.facebook.com/blockhousekitchen.constantia/",
        "mdi-facebook",
        "Facebook"
      ],
      [
        "https://www.instagram.com/blockhousekitchen/",
        "mdi-instagram",
        "Instagram"
      ],
      ["https://blockhousekitchen.co.za/", "mdi-web", "Website"]
    ]
  })
};
</script>

<style>
.v-card {
  border-radius: 0px;
}
</style>
